export default defineI18nConfig(() => ({
  legacy: false,
  globalInjection: true,
  fallbackLocale: "pt",
  messages: {
    pt: {
      "contact-email": "contato{'@'}aprender.design",
      wait: "Aguarde...",

      cancel: "Cancelar",
      save: "Salvar",
      close: "Fechar",
      "try-again": "Tentar novamente",

      "lesson-title-prefix": "Aula",
    },
    en: {
      "contact-email": "contact{'@'}aprender.design",
      wait: "Wait...",

      cancel: "Cancel",
      save: "Save",
      close: "Close",
      "try-again": "Try again",

      "lesson-title-prefix": "Lesson",
    },
  },
}));
